let map;

async function initMap(el, i) {
  // The position
  const position = { lat: Number(el.dataset.lat), lng: Number(el.dataset.lng) };

  const { Map } = await google.maps.importLibrary("maps");
  const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

  // The map, centered at position
  map = new Map(el, {
    zoom: 16,
    center: position,
    mapId: "MAP_" + i,
    disableDefaultUI: true,
  });

  // The marker, positioned at position
  const markerImg = document.createElement("img");

  markerImg.src = "/brand/map-pin.png";
  markerImg.width = 36;

  const marker = new AdvancedMarkerElement({
    map: map,
    position: position,
    content: markerImg,
    title: el.dataset.location,
  });
}

// Load Google Maps
const loadMaps = document.querySelectorAll(".map").forEach((el, i) => {
  if (el) {
    initMap(el, i);
  }
});

export { loadMaps };
