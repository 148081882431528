import { register } from "swiper/swiper-element-bundle.mjs";

import * as nav from "./modules/navigation.js";
import loadMaps from "./modules/maps.js";
import animateOnScroll from "./modules/observer.js";

// Register Swiper custom elements
register();

// Stop transitions before page has loaded
window.addEventListener(
  "load",
  (event) => {
    document.body.classList.remove("preload");
  },
  false,
);

$(".accordion-toggle").click(function () {
  $(this).next().slideToggle();
  $(this).toggleClass("border-black border-black/30");
});

$(".map-toggle").click(function () {
  const i = $(this).index();

  $(".map-toggle").addClass("bg-blue").removeClass("bg-blue-pale");
  $(this).addClass("bg-blue-pale").removeClass("bg-blue");

  $(".map-container").addClass("hidden").removeClass("flex");
  $(".map-container").eq(i).addClass("flex").removeClass("hidden");
});
