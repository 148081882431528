import { openModal, closeModal } from "./modal.js";

const menu = document.querySelector("#main-navigation");
const hamburger = document.querySelector("#hamburger");
const menuIcon = document.querySelector(".menuIcon");
const closeIcon = document.querySelector(".closeIcon");

// Toggle nav
hamburger.addEventListener("click", function () {
  menu.classList.contains("hidden") ? openNav() : closeNav();
});

// Hide nav on window resise
window.addEventListener("resize", (event) => {
  if (window.innerWidth > 1040 && menuIcon.classList.contains("hidden")) {
    closeNav();
  }
});

$(".subnav-toggle").click(function (e) {
  if (window.innerWidth < 1040) {
    e.preventDefault();
    $(this).next(".subnav").toggleClass("hidden");
  }
});

// Functions
const openNav = () => {
  menu.classList.remove("hidden");
  menuIcon.classList.add("hidden");
  closeIcon.classList.remove("hidden");
  openModal();
};

const closeNav = () => {
  menu.classList.add("hidden");
  menuIcon.classList.remove("hidden");
  closeIcon.classList.add("hidden");
  closeModal();

  $(".subnav").addClass("hidden");
};

export { openNav, closeNav };
